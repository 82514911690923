<template>
    <v-row class="">
        <!-- not modal -->
        <v-col sm="12" cols="12" class="pt-0">
            <h2 class="font_20 mb-0">{{ $t('session_setting') }}</h2>
            <p class="mb-0">{{ $t("description") }}</p>
            <v-row>
                <v-col sm="12" cols="12">
                    <template>
                        <LoadingMe
                            :isLoading="showLoading"
                            :fullPage="false"
                            :myLoading="true"/>
                        <v-simple-table class="">
                            <template>
                                <thead>
                                <tr>
                                    <th>{{ $t('start_session') }}</th>
                                    <th>{{ $t('currency') }}</th>
                                    <th>{{ $t('amount') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="pl in sessionStart" v-bind:key="pl.currencyId">
                                    <td></td>
                                    <td>{{ pl.code }}</td>
                                    <td>
                                        <v-text-field
                                            class="mt-1"
                                            v-model="pl.amount"
                                            outlined
                                            placeholder=""
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-bold">{{ $t('session_type') }}</td>
                                    <td>{{ $t('description') }}</td>
                                    <td style="" class="primary--text align-center justify-center d-flex text-bold">
                                        <v-select
                                            class="my-1"
                                            v-model="ses.sessionType"
                                            style="width:160px"
                                            :items="sessionTypes"
                                            outlined
                                            return-object
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </template>
                </v-col>
            </v-row>

            <v-divider/>

            <v-card outlined dense class="no_border function_footer">
                <v-btn color="primary" class="float-right white--text" @click="onSaveClose">
                    {{ $t('save') }}
                </v-btn>

            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import SessionSettingModel from "@/scripts/model/pos/SessionSetting"
import kendo from "@progress/kendo-ui";

const $ = kendo.jQuery;
const posHandler = require("@/scripts/posHandler");

const currencyHandler = require("@/scripts/currency/handler/currencyHandler")
const currencyType = {
    FUNCTIONAL_CURRENCY: 'fun-c',
    TRANSACTION_CURRENCY: 'txn-c',
    REPORTING_CURRENCY: 'rpt-c',
    TAX_EXCHANGE_CURRENCY: 'tax-c',
}
export default {
    components: {
        LoadingMe: () => import(`@/components/Loading`),
    },
    data: () => ({
        ses: new SessionSettingModel({}),
        showLoading: false,
        sessionStart: [],
        sessionTypes: ['Cash Count', 'Print Only'],
    }),
    methods: {
        async onSaveClose() {
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve("resolved");
                    this.showLoading = true
                    window.console.log(this.ses, this.sessionType, this.sessionStart)
                    this.ses.sessionStart = this.sessionStart
                    posHandler.sessionSettingPost(new SessionSettingModel(this.ses))
                        .then((response) => {
                            this.showLoading = false
                            if (response.data.statusCode === 201) {
                                this.$snotify.success('Success')
                            }
                        })
                        .catch((e) => {
                            this.showLoading = false
                            this.$snotify.error('Something went wrong')
                            this.errors.push(e);
                        });
                }, 10)
            });
        },
        async loadSession() {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved')
                    this.showLoading = true
                    posHandler.sessionSettingGet().then(response => {
                        if (response.data.statusCode === 200) {
                            this.showLoading = false
                            let data = response.data.data
                            if (data.length > 0) {
                                this.ses = data[0]
                                $.each(this.sessionStart, function (i, v) {
                                    let c = data[0].sessionStart.filter((obj) => {
                                        return obj.currencyId == v.currencyId
                                    })
                                    if (c.length > 0) {
                                        v.amount = c[0].amount
                                    }
                                })
                            }
                        }
                    }).catch()
                    {
                        this.showLoading = false
                    }
                }, 300)
            })
        },
        async loadCurrencyData() {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved')
                    this.showLoading = true
                    this.sessionStart = []
                    currencyHandler.list(currencyType.FUNCTIONAL_CURRENCY).then(response => {
                        if (response.data.statusCode === 200) {
                            this.currencyList = response.data.data
                            this.showLoading = false
                            if (this.currencyList.length > 0) {
                                let self = this
                                $.each(this.currencyList, function (i, v) {
                                    self.sessionStart.push({
                                        currencyId: v.id,
                                        code: v.code,
                                        amount: 0
                                    })
                                })
                                this.loadSession()
                            }
                        }
                    }).catch()
                    {
                        this.showLoading = false
                    }
                }, 300)
            })
        },
    },
    mounted: async function () {
        await this.loadCurrencyData()
    }
}
;
</script>
<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:first-child > td:not(.v-data-table__mobile-row) {
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.btn_save {
    color: #ffffff;
    text-transform: capitalize;
}

.w_100 {
    width: 100px !important;
}

.float-right {
    margin-top: 0px !important;
}

.float-left {
    margin-top: 0px !important;

}

.v-input--switch {
    margin-top: 0px;
    height: 30px;
}

.attachment_table .v-data-table__wrapper {
    border-bottom: none !important;
}


</style>